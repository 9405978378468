<head>
  <script src="https://unpkg.com/markerjs2/markerjs2.js"></script>
  <script>
    function showMarkerArea(target) {
      const markerArea = new markerjs2.MarkerArea(target);
      markerArea.addEventListener("render", (event) => (target.src = event.dataUrl));
      markerArea.show();
    }
  </script>
</head>

<div style="padding: 10px 1rem !important;">
  <h2 class=" pull-left">{{data.name}}</h2>
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<hr style=" margin-top: 2rem;">
<div style="padding: 0px 50px !important;">
  <section class="mt-1">
    <div class="row text-center" style="display: block">
      <div class="col-lg-12">
        <div class="zmain " style="overflow: hidden;">
          <iframe id="zmap" [src]="'assets/index.html?' + popup_img | safe : 'resourceUrl'" frameborder="0"
            style="width: 100% !important; height: 100% !important;"></iframe>
          <div [innerHTML]="image"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center mb-3">
        <button class="btn btn-success" type="submit" (click)="OnSubmit_list()"><span>
            Submit</span></button>
      </div>
    </div>

    <div class="row" *ngIf="popup_img">
      <div class="col-md-12 ">
        <form [formGroup]="dynamicForm" *ngIf="t.controls && t.controls.length>0">
          <div *ngFor="let ticket of t.controls; let i = index">
            <div [formGroup]="ticket">
              <div class="row mt-3 ml-0">

                <div class="col-md-4">
                  <label class="form-group has-float-label">
                    <span>Select Body Part List</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Damage" formControlName="body_part">
                    <ng-option *ngFor="let item of body_partlist" [value]="item.Usertype">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                  <div style="font-style: italic;" class="mt-2"
                    *ngIf="submitted && ticket.get('body_part').hasError('required')" class="required">
                    Field is required
                  </div>
                </div>

                <div class="col-md-4">
                  <label class="form-group has-float-label">
                    <span>Select Damage Type</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Damage" formControlName="damage_type">
                    <ng-option *ngFor="let item of damage_type2" [value]="item.Usertype">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                  <div style="font-style: italic;" class="mt-2"
                    *ngIf="submitted && ticket.get('damage_type').hasError('required')" class="required">
                    Field is required
                  </div>
                </div>

                <div class="col-md-4">
                  <label class="form-group has-float-label">
                    <span>Select Damage Severity</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Damage" formControlName="damage_serverity">
                    <ng-option *ngFor="let item of damage_Severity_type1" [value]="item.Usertype">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                  <div style="font-style: italic;" class="mt-2"
                    *ngIf="submitted && ticket.get('damage_serverity').hasError('required')" class="required">
                    Field is required
                  </div>
                </div>

                <div class="col-md-4  mt-4">
                  <label class="form-group has-float-label">
                    <span>Select Replace-Repair</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Replace-Repair" formControlName="damage_property"
                    (change)="Onselect($event,t.length)">
                    <ng-option *ngFor="let item of damage_property_type1" [value]="item.Usertype">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                  <div style="font-style: italic;" class="mt-2"
                    *ngIf="submitted && ticket.get('damage_property').hasError('required')" class="required">
                    Field is required
                  </div>
                </div>

                  <ng-container *ngIf="this.ticket.get('damage_property').value!=='Minor Repair'">
                    <div class="col-md-4 mt-4" *ngIf="paint_hide">
                      <label class="form-group has-float-label">
                        <span>Select Paint Type</span>
                      </label>
                      <ng-select appearance="outline" placeholder="Select Paint" formControlName="paint">
                        <ng-option *ngFor="let item of paint_type1" [value]="item.Usertype">
                          {{item.Usertype}}
                        </ng-option>
                      </ng-select>
                    </div>
                  </ng-container>

                <!--
                <div class="col-md-4 mt-4" *ngIf="paint_hide">
                  <label class="form-group has-float-label">
                    <span>Select Paint Type</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Paint" formControlName="paint">
                    <ng-option *ngFor="let item of paint_type1" [value]="item.Usertype">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                </div> -->

                <div class="col-md-4  mt-4">
                  <label class="form-group has-float-label">
                    <span>Select Internal Damage</span>
                  </label>
                  <ng-select appearance="outline" placeholder="Select Internal Damage"
                    formControlName="internal_damage">
                    <ng-option *ngFor="let item of Internal_damage_type1" [value]="item.id">
                      {{item.Usertype}}
                    </ng-option>
                  </ng-select>
                  <div style="font-style: italic;" class="mt-2"
                    *ngIf="submitted && ticket.get('internal_damage').hasError('required')" class="required">
                    Field is required
                  </div>
                </div>

                <div class="col-md-1 mt-3">
                  <div class="form-group mt-4" *ngIf="i!=0" style="position: relative">
                    <div type="button" class="btn btn-danger" (click)="deleteRow(i)"><i class="fa fa-trash"></i></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div style="position: relative; text-align: left;" class="ml-2">
            <button type="button" class="btn add_more" (click)="tickets()"><i class="fa fa-plus"></i></button>
          </div>&nbsp;
          <!-- <div class="col-md-12 text-center mb-3">
                <button class="btn btn-success" type="submit" (click)="OnSubmit_list()"><span>
                        Submit</span></button>
            </div> -->
        </form>
      </div>
    </div>
  </section>
</div>
