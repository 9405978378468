<div class="h-100 content-mobile">
  <app-color-switcher *ngIf="isMultiColorActive"></app-color-switcher>
  <!-- <div class="loading"></div> -->
  <router-outlet></router-outlet>
</div>

<div class="auth-main-card dispaly-desktop mt-5">
  <div class="auth-card">
      <div class="form-side" style="width: 100%;padding: 30px;">
          <div class="c-pointer" style="text-align: center;">
              <span class="logo-single"></span>
          </div>
              <div class="tooltip-right-bottom">
                  <div style="margin-top: 20px; text-align: center;">
                   <h3 style="font-size: 20px;font-weight: 700;"> Only support on mobile web browser</h3>
                  </div>
              </div>
      </div>
  </div>
</div>
